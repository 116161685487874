/*.item must be first
.item {
    align-items: center;
    color:  #808e9b;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 30px;
    justify-content: center;
    width: 30px;
  }
  
  .disabled-page {
    color: #808e9b;
  }
  
  .active {
    border: solid 1px #808e9b;
    border-radius: 40px;
    color: #40788B
  }
  
  .break-me {
  }
  
  /* .next {
    border-left: solid 1px #808e9b;
    font-size: 4px;
    height: 60px;
    position: absolute;
    right: 0;
    width: 150px;
  } */

/*  .pagination {
    align-items: center;
    background-color: #ffffff00;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: flex-end;
    list-style-type: none;
    position: relative;
    width: 1000px;
  }
  
  .pagination-page {
    font-weight: 700;
  }
  
  /* .previous {
    border-right: solid 1px #808e9b;
    font-size: 4px;
    height: 60px;
    left: 0;
    position: absolute;
    width: 150px;
  } */
.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style: none;
  cursor: pointer;
  margin-top: 1rem;
}

.pagination a {
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 9999px;
  margin-right: 10px;
  width: 30px;
  border: 1px solid #808e9b;
  color: #808e9b;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: #40788b;
}

.pagination__link:hover {
  color: #fff;
  background: #40788b;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}
