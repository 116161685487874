@import url("https://fonts.googleapis.com/css2?family=Average&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Capriola&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap");
@import "shepherd.js/dist/css/shepherd.css";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  h1 {
    @apply font-titleH1 text-4xl text-add-dark;
  }

  p {
    @apply text-base;
  }

  .link {
    @apply underline hover:no-underline hover:text-add-green-light text-add-green font-semibold decoration-add-green decoration-2 underline-offset-4 transition duration-200 ease-in-out;
  }

  .button-add {
    @apply text-white rounded-lg px-2 transition-all duration-200 ease-in-out;
  }

  .button-add:hover,
  .button-add:focus {
    @apply bg-cyan-700 outline-none ring-2 ring-offset-2 ring-cyan-700;
  }

  input {
    @apply focus:border-add-blue-dark focus:ring-1 focus:ring-add-blue-dark
    focus:invalid:border-add-blue-dark focus:invalid:ring-add-blue-dark hover:bg-blue-100 caret-add-blue-dark focus:caret-add-blue-dark focus:outline-add-blue-dark;
  }

  input[type="range"]::-webkit-slider-thumb {
    @apply bg-add-blue-dark;
  }

  .pagination-center button {
    @apply flex items-center justify-center;
  }

  [data-popper-arrow]:after {
    @apply bg-add-green;
  }

  li#ressources > div:first-child {
    @apply w-full;
  }

  #selectUserTasksType svg {
    @apply hidden;
  }
}
